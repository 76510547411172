import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import RequestDemo from 'components/shared/request-demo';

import Circle from './images/circle.inline.svg';

const items = [
  {
    number: 1,
    title: 'Clean and Aggregate Data',
    description:
      'For each of our datasets, we use machine learning to reduce the raw behavioral data to a representation of each identifier as a vector of numbers.',
    imageName: 'cleanAndAggregate',
  },
  {
    number: 2,
    title: 'Capture Signal & Preserve Privacy',
    description:
      'These numbers contain the statistical information needed to make predictions about future behavior, while revealing none of the details about past behavior.',
    imageName: 'captureSignal',
  },
  {
    number: 3,
    title: 'Produce Safe Machine-Readable Data',
    description:
      'This approach results in a representation that can be used in the models that we or our clients develop, but doesn’t make sense to a human and can only be interpreted in the context of other vectors generated by the same process.',
    imageName: 'produceSafe',
  },
  {
    number: 4,
    title: 'Further Ethical Modeling',
    description:
      'Our vectors can also be tuned to improve prediction of specific behaviors, and to remove information about protected characteristics to reduce risks of violating privacy or supporting discrimination.',
    imageName: 'furtherEthicalModeling',
    isRequestDemo: true,
  },
];

const Slide = ({ number, title, description, image, isRequestDemo }) => {
  const isFirstSlide = number === 1;
  return (
    <Container className="relative flex flex-col justify-between pt-8 pb-16 lg:flex-row lg:py-24">
      <div className="max-w-[592px] pt-8">
        <div className="relative mr-5 w-9 h-9">
          <Circle />
          <span className="absolute font-bold text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            {number}
          </span>
        </div>
        <Heading className="my-5" tag="h2" size="xl" theme="black">
          {title}
        </Heading>
        <div className="flex">
          <p className="text-2xl">{description}</p>
        </div>
        {isRequestDemo && <RequestDemo className="md:max-w-[592px] mt-8" />}
      </div>
      <GatsbyImage
        className="top-0 right-auto mx-auto mt-8 ml-auto lg:mt-0 lg:mx-0 lg:-right-16 xl:-right-32"
        image={getImage(image)}
        objectFit="contain"
        loading={isFirstSlide ? 'eager' : 'lazy'}
        alt=""
      />
    </Container>
  );
};

const Slides = () => {
  const { cleanAndAggregate, captureSignal, produceSafe, furtherEthicalModeling } =
    useStaticQuery(graphql`
      query {
        cleanAndAggregate: file(
          relativePath: { eq: "pages/behavior-genome/slides/clean-and-aggregate.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
        captureSignal: file(
          relativePath: { eq: "pages/behavior-genome/slides/capture-signal.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
        produceSafe: file(relativePath: { eq: "pages/behavior-genome/slides/produce-safe.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 564)
          }
        }
        furtherEthicalModeling: file(
          relativePath: { eq: "pages/behavior-genome/slides/further-ethical-modeling.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(width: 564)
          }
        }
      }
    `);

  const imageCollection = {
    cleanAndAggregate,
    captureSignal,
    produceSafe,
    furtherEthicalModeling,
  };

  return (
    <section className="lg:overflow-x-hidden">
      {items.map(({ imageName, ...props }, index) => {
        const image = imageCollection[imageName];
        return <Slide image={image} {...props} key={index} />;
      })}
    </section>
  );
};

export default Slides;
