import React from 'react';

import CardWithNumber from 'components/shared/card-with-number';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const steps = [
  {
    title: 'Matching',
    description:
      'We use custom probabilistic models to build connections across datasets. These models capture patterns in behavior that allow us to identify relationships between individuals and devices across datasets. However, rather than trying to resolve identity to a single person, we use these probabilistic models to maintain uncertainty over individual identities. This allows us to report aggregate statistics for a set of identifiers across datasets or produce representations for individual identifiers that draw on multiple datasets without explicitly reidentifying individuals.',
  },
  {
    title: 'Predictions',
    description:
      'We build predictive models of individual behavior using techniques from machine learning combined with insights from behavioral science. The resulting models are able to generate high-fidelity predictions about specific behaviors.',
  },
];

const ModelingSteps = () => (
  <section className="pt-16 pb-20 lg:py-36">
    <Container>
      <Heading className="text-center" tag="h2" size="xl">
        Modeling
      </Heading>
      <div className="grid grid-cols-1 gap-8 mt-16 lg:grid-cols-2">
        {steps.map(({ title, description }, index) => (
          <CardWithNumber title={title} description={description} index={index} key={index} />
        ))}
      </div>
    </Container>
  </section>
);

export default ModelingSteps;
