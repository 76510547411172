import React from 'react';

import ModelingSteps from 'components/pages/behavior-genome/modeling-steps';
import Slides from 'components/pages/behavior-genome/slides';
import RequestDemoHero from 'components/shared/request-demo-hero';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const pageTitle = 'Behavior Genome';
const pageDescription =
  'The Behavioral Genome is a product of our thesis that businesses don’t require raw behavioral data to optimize performance or ROI. One of Yobi’s goals is to reduce the proliferation of identifiable consumer data. Rather than broker raw data, Yobi commercializes a non-identifiable, machine-readable representation of behavior. This representation maintains statistical equivalence with raw data without ever revealing personal information. As a result, businesses can integrate Yobi’s Behavioral Genome into all data acquisition and customer science capabilities.';

const BehaviorGenomePage = () => (
  <MainLayout pageMetadata={SeoMetadata['behavior-genome']}>
    <RequestDemoHero title={pageTitle} description={pageDescription} />
    <Slides />
    <ModelingSteps />
  </MainLayout>
);

export default BehaviorGenomePage;
